canvas {
  border: 1px solid #000;
}

button {
  font-size: 14px;
}

#messageDiv {
  padding: .5em;
}

#div1 {
  display: flex;
}

p {
  margin-bottom: 0;
  font-size: small;
}

a {
  font-size: small;
}

hr {
  border-top-style: dotted;
  margin-left: 0;
}

/*# sourceMappingURL=index.ff86b302.css.map */
