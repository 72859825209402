canvas { 
    border: 1px solid black; 
}

button {
    font-size: 14px;
}

#messageDiv {
    padding: 0.5em;
}

#div1 {
    display: flex;
}

p {
    font-size: small;
    margin-bottom: 0;
}

a {
    font-size: small;
}

hr {
    margin-left: 0;
    border-top-style:dotted;
}